<template>
    <UniversalTable
        :model="pageModel"
        :pageName="pageName"
        :tableType="tableType"
        :endpoint="endpoint"
        :openHandler="openHandler" />
</template>

<script>
import UniversalTable from '@/components/TableWidgets/UniversalTable'
export default {
    components: {
        UniversalTable
    },
    data() {
        return {
            pageModel: 'catalogs.ContractorProfileRequestModel',
            pageName: 'catalogs.ContractorProfileRequestModel',
            tableType: 'moderation',
            endpoint: '/catalogs/profile_requests/'
        }
    },
    methods: {
        openHandler(record) {
            let query = Object.assign({}, this.$route.query)
            if(!query?.organization_drawer) {
                query.organization_drawer = 'detail'
                query.organization_id = record.organization.id
                this.$router.push({name: 'team', query})
            }
        }
    }
}
</script>